<template>
  <a class="cell-link" :target="target" :href="link">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'AppTableCellLink',
  props: {
    link: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
  data() {
    return {
      positions: {
        start: 'cells--start',
        end: 'cells--end',
        center: 'cells--center',
        between: 'cells--between'
      },
    }
  },
}
</script>

<style scoped lang="sass">
.cell-link
  width: 100%
  height: 100%
  color: inherit
  display: flex
  flex-direction: column
  justify-content: center
</style>
